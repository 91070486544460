@import 'main.scss';
.titleContainer {
  width: 100%;
  pointer-events: none;
  padding: 0 24px;
  margin-top: 150px;
  margin-bottom: 80px;

  @media screen and (min-width: 1000px) {
    margin-bottom: 70px;
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span,
    p {
      padding-top: 1px;
      display: block;
      @media screen and (min-width: 1000px) {
        padding-top: 14px;
      }

    }

    span {
      padding-left: 6px;

      @media screen and (min-width: 1601px) {
        text-wrap: nowrap;
      }
    }

    @media screen and (min-width: 1600px) {
      flex-direction: row;
      gap: 32px;
    }
  }
}