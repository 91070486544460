@import 'main.scss';
.sectionContainer {
  height: auto;
  width: 100%;
  background: transparent;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    min-height: 100vh;
  }

  h2 {
    display: flex;
    flex-direction: column;

    :first-child {
      padding: 0px 3px;
      width: fit-content;

      @media screen and (min-width: 1000px) {
        padding: 0px 10px;
      }
    }
  }

  .recognitionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: 65px 0px 60px 0px;

    @media screen and (min-width: 1000px) {
      margin: 105px 0px 60px 0px;
    }

    .recognitionItem {
      overflow: hidden;
      position: relative;
      padding: 24px 0px;
      transition: all 0.3s ease-in-out;

      border-bottom: 1px solid #bcbcbc;

      @media screen and (min-width: 1000px) {
        padding: 30px 0px;
        border-bottom: none;
      }

      .svgObject {
        transition: all 0.15s ease-in-out;
        filter: invert(0);
      }

      .itemLogo {
        left: 50%;
        margin: 0 0 0 -50px;
        width: 100px;
        height: auto;
        position: absolute;
        opacity: 0;
        z-index: 2;
        display: none;

        @media screen and (min-width: 1000px) {
          display: block;
        }
      }

      .itemDetail {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: flex-start;
        pointer-events: none;

        @media screen and (min-width: 1000px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 0px;
        }
      }

      @media screen and (min-width: 1000px) {
        @media screen and (hover: hover) {
          &:hover {
            .text {
              color: var(--secondary);

              span {
                letter-spacing: 0.5rem;
              }
            }
          }

          &:hover > div > div {
            color: var(--white);
          }

          &:hover > div > div > [data-name="NeedColorSwitch"],
          &:hover > div > div > [data-name="NeedColorSwitch"] {
            filter: brightness(0) invert(1);
          }
        }
      }
    }

    .svgContainer {
      z-index: 1;
      display: none;

      @media screen and (min-width: 1000px) {
        display: block;
      }
    }

    .text {
      display: flex;
      gap: 4px;
      flex-direction: column;
      color: var(--primary);
      z-index: 1;

      @media screen and (min-width: 1000px) {
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }

      span {
        font-family: var(--font-sora);
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        transition: all 0.15s ease-in-out;
        color: var(--secondary);

        small {
          font-size: 18px;

          @media screen and (min-width: 1000px) {
            display: none;
          }
        }
      }

      h3 {
        font-family: var(--font-avant);
        font-weight: 800;
        text-transform: uppercase;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.5px;
        transition: all 0.15s ease-in-out;

        @media screen and (min-width: 1000px) {
          font-size: 45px;
          line-height: 50px;
        }

        @media screen and (min-width: 1600px) {
          font-size: 50px;
          line-height: 60px;
        }
      }
    }
  }

  .recognitionItem::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    @media screen and (min-width: 1000px) {
      background-color: var(--primary);
      transition: all 0.25s;
    }
  }

  @media screen and (hover: hover) {
    .recognitionItem:hover::before {
      top: 0;
    }
  }
}
